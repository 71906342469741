@import "variables";

@import "theme/theme";

@import "~select2/src/scss/core";

.logo {
    width: 100%;
    height: auto;
}

.logo-light {
    filter: invert(100%) sepia(95%) saturate(0%) hue-rotate(162deg) brightness(105%) contrast(105%);
}

.logo-dark {
    filter: brightness(0) saturate(100%) invert(7%) sepia(6%) saturate(6256%) hue-rotate(114deg) brightness(89%) contrast(94%);;
}

@media (max-width:767px){
    .logo {
       display: block;
       width: auto;
       height: 30px;
       max-width: 100%;
       margin: 0 auto;
    }
}

.card-header .card-title {
    margin-bottom: 0;
    color: $primary;
    font-weight: bold;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--theme-light);
}

.btn-lightgray{
    color: #000000;
    background-color: rgb(224, 224, 224);
    border-color: rgb(224, 224, 224);
}

.meilistats-info-table{
    width: 50%;

    tr td:first-child{
        font-weight: 600;
    }
}

.meili-filters{
    .filter{
        max-height: 200px;
        overflow-y: scroll;
        background-color: rgb(251, 251, 251);
        margin-bottom: 30px;
        a{
            font-size: 15px;
            text-decoration: none;
            color: black;
            &.selected{
                font-weight: bold;
            }

            &.remove{
                border-radius: 50%;
                padding: 5px;
                font-weight: bold;
            }
        }
    }
}

.meili-products{
    .item{
        position: relative;
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;

        img{
            width: 140px;
            height: 100px;
            border: 1px solid #eee;
            object-fit: contain;
            object-position: center;
        }

        .shopcount{
            position: absolute;
            left: 0;
            background: #363636;
            padding: 0 5px;
            border-radius: 3px;
            color: white;
        }

        .hit-info {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 100px;
            width: 300px;
            display: none;
            background-color: #fff;
            padding: 0.5rem;
            font-size: 0.8rem;
            line-height: 0.8rem;
            border: 1px solid #000;
            box-shadow: 0 0 0.5rem rgb(0 0 0 / 20%);
            z-index: 1;

            pre{
                display: block;
                margin-top: 0;
                margin-bottom: 1rem;
                overflow: auto;
                font-size: .875em;
            }
        }
        .sortkey{
            position: absolute;
            left: 0;
            bottom: 0;
            background: #363636;
            padding: 0 5px;
            border-radius: 3px;
            color: white;
        }

        &.open{
            .hit-info {
                display: block;
            }
        }
    }
}

.float-right{
    float: right;
}

.bg-gray{
    background-color: rgb(224, 224, 224);
}

.empty-row {
    background: lightyellow;
    font-size: 11px;
}

.empty-row td,
.empty-row th {
    padding-top: 2px;
    padding-bottom: 2px;
}

.new-suggestion-block td,
.new-suggestion-block th {
    border-top: 2px solid #d5c020;
}

.hover-pointer:hover{
    cursor: pointer;
}

.hide-flush-btn{
    display: none;
}

tr.old td{
    border-bottom: 2px solid #d5c020;
}

.border-bottom-light-gray {
    border-bottom: 1px solid lightgray;
}

.bg-light-green {
    background-color: #D0E9C6;
}

.table-compact img {
    width: auto;
    max-height: 100px;
    border: none !important;
    background: pink;
    outline: 2px solid red !important;
}

.table-comprehensive img {
    max-width: 1000px; 
    border: 13px solid red;
    background: pink;
}

.paymentmethod {
    max-width: 15px;
    height: auto;
}

.qualitymark {
    max-width: 15px;
    height: auto;
}

.log-exception-row {
    background-color: pink !important;
}

.swal2-75width {
    width: 75vw !important;
}

.custom-swal-width{
    width: 300px;
}

ul.scrollable {
    overflow-y: scroll;
    height: 70vh;
}

img.small {
    width: 100px;
    height: auto;
}

.small-fs-td{
    font-size: 0.7rem !important;
}
